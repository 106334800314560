import React from "react";
import ResetPassword from "../components/molecules/ResetPassword/ResetPassword";

interface ILocation {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  key: string;
  origin: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  state: null;
}

const ResetPasswordTemplate = ({ location }: { location: ILocation }) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        margin: "0 5%",
      }}
    >
      <ResetPassword path={location.pathname} />
    </div>
  );
};

export default ResetPasswordTemplate;
