// extracted by mini-css-extract-plugin
export var button = "ResetPassword-module--button--P0Pjr";
export var container = "ResetPassword-module--container--79DtM";
export var descritpion = "ResetPassword-module--descritpion--ayX3D";
export var errorHandling = "ResetPassword-module--error-handling--Bvx6t";
export var input = "ResetPassword-module--input--xCJIx";
export var inputPadding = "ResetPassword-module--input-padding--L+jb+";
export var inputWrapper = "ResetPassword-module--input-wrapper--CNA-L";
export var label = "ResetPassword-module--label--IRDs4";
export var login = "ResetPassword-module--login--W+hZa";
export var title = "ResetPassword-module--title--NDRof";