/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */

import React, { useEffect, useState } from "react";
import * as s from "./ResetPassword.module.scss";
import { Buffer } from "buffer";
import { useMutation } from "@apollo/client";
import { CUSTOMER_PASSWORD_RESET } from "../../../g/mutations/customer";
import { navigate } from "gatsby-link";
import classNames from "classnames";
import { toast } from "react-toastify";

const cn = classNames.bind(s);

const ResetPassword = ({ path }: { path: string }) => {
  const [resetToken, setResetToken] = useState("");
  const [userId, setUserId] = useState("");
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMsg, setErrorMsg] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);

  const base64Encode = (data: string) => {
    const userDataId = "gid://shopify/Customer/" + data;
    const bufferObj = Buffer.from(userDataId, "utf8");
    const base64String = bufferObj.toString("base64");
    return base64String;
  };

  useEffect(() => {
    const url = path.split("/");
    if (url[url.length - 1] != "reset-password") {
      setResetToken(url[url.length - 1]);
      setUserId(base64Encode(url[url.length - 2]));
    }
  }, []);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordObject({
      ...passwordObject,
      [e.target.name]: e.target.value,
    });
  };

  const [resetPassword] = useMutation(CUSTOMER_PASSWORD_RESET, {
    variables: {
      id: userId,
      input: {
        resetToken: resetToken,
        password: passwordObject.password,
      },
    },
    onCompleted: (data) => {
      if (
        data?.customerReset?.customerAccessToken !== null &&
        data?.customerReset?.customer !== null
      ) {
        toast("Your password is changed!", { type: "success" });
        navigate("/login");
      }

      if (data?.customerReset?.customerUserErrors[0]) {
        setErrorMsg(data?.customerReset?.customerUserErrors[0].message);
      }
    },
  });

  const handleSubmit = () => {
    if (passwordObject.password === passwordObject.confirmPassword) {
      resetPassword();
      setInvalidPassword(false);
    } else {
      setInvalidPassword(true);
    }
  };

  return (
    <div className={s.container}>
      <p className={s.title}>
        Reset <span>password</span>
      </p>
      <p className={s.descritpion}>
        Enter your new password. <br />
        Password must be atleast 6 characters long. <br />
        Your new password can’t match your old password
      </p>

      <p className={s.label}>Password</p>

      <div className={s.inputWrapper}>
        <input
          type="password"
          className={s.input}
          onChange={(e) => handleChange(e)}
          name="password"
          placeholder="Enter new password"
        ></input>
      </div>

      <p className={s.label}>Confirm password</p>

      <div className={s.inputWrapper}>
        <input
          type="password"
          className={cn(s.input, {
            [s.inputPadding]: invalidPassword === true,
          })}
          onChange={(e) => handleChange(e)}
          name="confirmPassword"
          placeholder="Repeat new password"
        ></input>
        {invalidPassword && (
          <p className={s.errorHandling}>
            <span>*</span>
            Passwords are not matching
          </p>
        )}

        {errorMsg && (
          <p className={s.errorHandling}>
            <span>*</span>
            {errorMsg}. Try again!
          </p>
        )}
      </div>

      <button className={s.button} onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default ResetPassword;
